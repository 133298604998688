var TransferType;
(function (TransferType) {
    TransferType["TRANSFER"] = "Transfer";
    TransferType["CASH"] = "Cash";
})(TransferType || (TransferType = {}));
var TransferMethod;
(function (TransferMethod) {
    TransferMethod["FULL"] = "Full";
    TransferMethod["PERTERM"] = "PerTerm";
    TransferMethod["NORMAL"] = "Normal";
    TransferMethod["TRANSFERASGROUP"] = "TransferAsGroup";
    TransferMethod["TRANSFERPERTERM"] = "TransferPerTerm";
})(TransferMethod || (TransferMethod = {}));
var PositionList;
(function (PositionList) {
    PositionList["manager"] = "Manager";
    PositionList["director"] = "Director";
})(PositionList || (PositionList = {}));
var PajakType;
(function (PajakType) {
    PajakType["PAJAK"] = "Pajak";
    PajakType["NONPAJAK"] = "NonPajak";
})(PajakType || (PajakType = {}));
var PajakMethod;
(function (PajakMethod) {
    PajakMethod["INCLUDED"] = "Included";
    PajakMethod["EXCLUDED"] = "Excluded";
})(PajakMethod || (PajakMethod = {}));
var PajakCategory;
(function (PajakCategory) {
    PajakCategory["PPH21"] = "PPh21";
    PajakCategory["PPH23"] = "PPh23";
    PajakCategory["PPH23PLUSPPN"] = "PPh23PlusPPN";
    PajakCategory["PPH26"] = "PPh26";
    PajakCategory["PPH2620Percent"] = "PPh2620Percent";
    PajakCategory["Ph23PlusPPNInc"] = "Ph23PlusPPNInc";
    PajakCategory["PPh23PlusPPNExc"] = "PPh23PlusPPNExc";
    PajakCategory["PPh4ayat2"] = "PPh4ayat2";
    PajakCategory["Ph24PlusPPNInc"] = "Ph24PlusPPNInc";
    PajakCategory["PPh24PlusPPNExc"] = "PPh24PlusPPNExc";
})(PajakCategory || (PajakCategory = {}));
var NpwpStatus;
(function (NpwpStatus) {
    NpwpStatus["NPWP"] = "have-npwp";
    NpwpStatus["NotNPWP"] = "not-have-npwp";
})(NpwpStatus || (NpwpStatus = {}));
var TermStatus;
(function (TermStatus) {
    TermStatus["NEXTTERM"] = "NextTerm";
    TermStatus["ALLTERMSCOMPLETED"] = "AllTermsCompleted";
})(TermStatus || (TermStatus = {}));
var SOWStatus;
(function (SOWStatus) {
    SOWStatus["SOW"] = "Suitable";
    SOWStatus["NotSOW"] = "NotSuitable";
})(SOWStatus || (SOWStatus = {}));
export { TransferType, TransferMethod, PositionList, PajakType, PajakMethod, PajakCategory, NpwpStatus, TermStatus, SOWStatus };
